import React from 'react';

const ProblemStatement1 = () =>{
    return (
        <div className='problem_statement' style={{color:"white",width:"90vw",margin:"0 auto"}}>
            <p className='apply_details'><span>Problem Statement :</span> AI-Powered Purchasing Management Solution for SMEs</p>
            <br />
            <p className='problem_text'><span>Problem Overview :</span>Purchasing is a critical function for businesses that involves acquiring goods and services from external sources. Small and medium-sized enterprises (SMEs) often face challenges in managing their purchasing activities efficiently due to the complexity of the process and limited resources. To address these challenges, we propose the development of an AI-based solution that assists SMEs in managing their purchasing activities effectively. This solution will consist of two main components: a dashboard and a personal assistant.</p>
            <br />
            <p className='problem_subheading'>Dashboard</p>
            <br />
            <p className='problem_text'><span>Objective : </span>The dashboard will provide an integrated platform for SMEs to manage and optimise their purchasing activities.</p>
            <br />
            <p className='problem_subheading'>Features</p>
            <br />
            <p className='problem_text'>1. Purchasing Process Overview: A comprehensive view of ongoing purchasing tasks, projects, and their current status.<br />
2. Performance Analytics: Data and insights such as market trends, supplier ratings, cost savings, and risk analysis to make informed decisions.<br />
3. Supplier Management: Tools for managing and evaluating suppliers' performance.<br />
4. Cost Optimization: Insights to reduce costs while maintaining quality.Considering the amount of raw materials present in the inventory and also the past few days demand thereby predicting the future demand and the profit for the same the model should predict the optimal purchase quantity by the SME.<br />   
5. Risk Assessment: Identify and manage potential risks associated with suppliers and purchases.<br /><br/>
<span>Additionals:</span><br />1. Sustainability Monitoring: Track and improve sustainability practices in purchasing.
2. Progress Tracking: Real-time updates on purchasing tasks and projects.
</p>
            <br /><br />
            <p className='problem_subheading'>Personal Assistant</p><br />
            <p className='problem_text'><span>Objective : </span>The personal assistant will be a conversational agent that streamlines and automates purchasing tasks.<br /><br/><span>Features:</span><br/>1. Conversational Interface: Natural language communication for answering queries, providing suggestions, and executing commands.<br />
2. Supplier Search: Assist in finding the best suppliers based on specific requirements.<br/>
3. Issue Resolution: Handle issues and complaints efficiently.Implement a basic algorithm to resolve the issues raised while raising the ticket and manage them if the issue is found beyond the algorithm.<br/><br/>
<span>Additionals:</span><br/>1. Invoice Verification: Automate the verification of invoices.</p><br />
            <p className='problem_text'><span>Objectives:</span><br/><br/>The solution aims to achieve the following objectives:<br/>
1. Time and Effort Reduction: Minimise the time and effort required for purchasing activities by automating routine tasks and streamlining processes.<br/>
2. Quality and Accuracy Improvement: Enhance the quality and accuracy of purchasing decisions by providing data-driven insights and assistance.<br/>
3. Transparency and Accountability: Improve transparency and accountability within the purchasing process, making it easier to track and manage.<br/>
4. Cost-effectiveness and Profitability: Increase the cost-effectiveness of purchasing outcomes, leading to enhanced profitability.<br/>
5. Sustainability and Social Responsibility: Support sustainability and social responsibility in purchasing practices by providing tools and data for making responsible choices.<br/><br/><span>Target Users:</span><br/>
The primary target users for this solution are small and medium-sized enterprises (SMEs) looking for a simple and affordable way to manage their purchasing operations. SMEs often lack the resources to implement complex procurement systems and need a cost-effective solution to optimise their purchasing activities</p><br/>  
            <p className='problem_subheading'>Evaluation Criteria</p>
            <p className='problem_text'>Judges will evaluate hackathon submissions based on the following criteria:
            <br/><br/>
<span>1. Innovation:</span> How creative and novel is the solution in addressing SMEs' purchasing challenges?<br/>
<span>2. Functionality:</span> Does the solution effectively address the objectives stated in the problem statement?<br/>
<span>3. Usability:</span> Is the solution user-friendly and accessible to non-technical users, primarily SMEs?<br/>
<span>4. Impact:</span> What potential impact can the solution have on the efficiency and profitability of SMEs?<br/>
<span>5. Technical Implementation:</span> How well are AI techniques integrated into the solution?<br/>
<span>6. Scalability:</span> Does the solution have the potential to scale and adapt to different SME requirements?<br/>
<span>7. Sustainability and Responsibility:</span>Does the solution promote sustainability and ethical purchasing practices?<br/>
Join us in solving a critical challenge for SMEs and revolutionise the way they manage their purchasing operations with AI. Your solution can make a significant impact on businesses and sustainability practices. Good luck, and let's innovate together!
</p>
        </div>
    )
}

export default ProblemStatement1;